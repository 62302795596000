.wrapper {
    height: 100vh;
    /*This part is important for centering*/
    display: grid;
    place-items: center;
  }
  
  .typingDemo {
    width: 30ch;
    animation: typing 2s steps(22), blink .5s step-end infinite alternate;
    /* animation: typing 3.5s steps(30, end),blink-caret .5s step-end infinite; */
    white-space: nowrap;
    overflow: hidden;
    border-right: 4px solid;
    font-family: monospace;
    font-size: 4em;
    color: lavenderblush;
  }
  
  @keyframes typing {
    from {
      width: 0
    }
  }
      
  @keyframes blink {
    50% {
      border-color: transparent
    }
  }
  